// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-histoire-js": () => import("../src/pages/histoire.js" /* webpackChunkName: "component---src-pages-histoire-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instagram-js": () => import("../src/pages/instagram.js" /* webpackChunkName: "component---src-pages-instagram-js" */),
  "component---src-pages-la-maison-js": () => import("../src/pages/la-maison.js" /* webpackChunkName: "component---src-pages-la-maison-js" */),
  "component---src-pages-les-inseparables-js": () => import("../src/pages/les-inseparables.js" /* webpackChunkName: "component---src-pages-les-inseparables-js" */),
  "component---src-pages-shop-js": () => import("../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */)
}

